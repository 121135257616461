import {
  Cell,
  DefaultValidationMessages,
  DropdownComponent,
  meta,
  Meta,
  ModelState,
  toLocalDate,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { plainToClass, Transform, Type } from 'class-transformer';
import {
  AUMSourceEnum,
  CurrencyEnum,
  EntityTypeEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
  PublicReturnSourceEnum,
  StreamSourceEnum,
} from '../../enums/enums';
import {
  IPeriodicStream,
  IPeriodicStreamPoint,
  PERIODIC_STREAMS,
  PeriodicStreamType,
} from '../../services/periodic-streams.service';
import { ManagementCompany, Program } from '../company/company.model';
import { Fund } from '../fund/fund.model';
import { Stream } from './stream.model';
import { StreamSpreadSheet } from './stream.spreadsheet';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { BulkAUM } from '../../services/aum.service';

export class StreamMeta extends Meta {
  classType = 'StreamMeta';
  entityId: number;
  entityTypeId?: EntityTypeEnum;
  entityName: string;
  streamType: PeriodicStreamType;
  __group = 'entityName';
  static providerServices: Map<PeriodicStreamType, any> = new Map();

  @Type(() => Date)
  streamStartDate: Date;

  @Type(() => StreamSpreadSheet)
  @meta({ navigation: true })
  streamSpreadSheet: StreamSpreadSheet;

  //#region Common Selections

  @Type(() => Stream)
  selectedStream: Stream;

  @Type(() => Stream)
  markedStreams: Array<Stream>;

  @Type(() => Date)
  selectedAsOf: Date;

  @Type(() => Date)
  emptySelectedAsOf: Date;

  @Type(() => Date)
  selectedMinDate: Date;

  @Type(() => Date)
  selectedMaxDate: Date;

  selectedSource: StreamSourceEnum | PublicReturnSourceEnum | AUMSourceEnum;

  sources: Array<{ key: any; value: string }>;

  //#endregion

  //#region AUM Selections

  @meta({ historyLength: 3 })
  selectedCurrency: string;
  currencies: Array<{ key: any; value: string }> = CurrencyEnum.toValueValue();

  //#endregion

  //#region Public Returns Selections

  selectedFeeType: PublicReturnFeeEnum;
  feeTypes: Array<{ key: any; value: string }> =
    PublicReturnFeeEnum.toKeyValue();

  selectedClassification: PublicReturnGeneralClassificationEnum;
  classifications: Array<{ key: any; value: string }> =
    PublicReturnGeneralClassificationEnum.toKeyValue();

  selectedEstimationAsOf: Date;

  //#endregion

  @Type(() => Stream)
  @meta({ alias: '(As Of [asOf])', navigation: true })
  stream: Array<Stream>;

  columnTitles = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  auditURL = `periodicdata/returnprofile/{0}/{1}/stream/audit?asOf={2}`;

  toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program,
    streamProp: string
  ) {
    StreamMeta.toggleSpreadSheet(entity, streamProp);
  }

  toggleSpreadSheetHistory() {
    return StreamMeta.toggleSpreadSheetHistory(this);
  }

  select(selections: {
    selectedStream: Stream;
    markedStreams: Array<Stream>;
    selectedCell: Cell;
  }) {
    this.selectedStream = selections.selectedStream;
    this.markedStreams = selections.markedStreams;
    if (this.markedStreams?.length <= 1 && this.selectedStream) {
      this.selectedSource = this.selectedStream.source;
      if (this.streamType === PERIODIC_STREAMS.PUBLICRETURNS) {
        this.selectedFeeType = this.selectedStream.feeType;
        this.selectedClassification = this.selectedStream.classification;
        this.selectedEstimationAsOf = this.selectedStream.estimationAsOf;
        this.selectedMinDate = new Date(
          this.selectedStream.asOf.getFullYear(),
          this.selectedStream.asOf.getMonth() - 1,
          0
        );
        this.selectedMaxDate = new Date(
          this.selectedStream.asOf.getFullYear(),
          this.selectedStream.asOf.getMonth() + 1,
          1
        );
      }
    } else {
      this.selectedSource = undefined;
      if (this.streamType === PERIODIC_STREAMS.PUBLICRETURNS) {
        this.selectedFeeType = undefined;
        this.selectedClassification = undefined;
        this.selectedEstimationAsOf = undefined;
        this.selectedMinDate = undefined;
        this.selectedMaxDate = undefined;
      }
    }

    if (!this.selectedStream) {
      let years = [...this.streamSpreadSheet.settings.rowTitles];
      let year = +years[selections.selectedCell.row];
      let month = selections.selectedCell.col;
      this.emptySelectedAsOf = new Date(year, month + 1, 0);
    } else {
      this.emptySelectedAsOf = undefined;
    }
  }

  static toggleSpreadSheetHistory(self: StreamMeta) {
    self.streamSpreadSheet.showHistory = !self.streamSpreadSheet.showHistory;
  }

  static toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program,
    streamProp: string
  ) {
    entity[`${streamProp}Meta`].streamSpreadSheet.isVisible =
      !entity[`${streamProp}Meta`].streamSpreadSheet.isVisible;

    if (!entity[`${streamProp}Meta`].streamSpreadSheet.isVisible) {
      entity[`${streamProp}Meta`].streamSpreadSheet.showHistory = false;
    }
  }

  //Common

  setAsOf(asOf: Date) {
    let stream = this.stream?.find(
      (point) =>
        point?.asOf?.getFullYear() === asOf.getFullYear() &&
        point?.asOf?.getMonth() === asOf.getMonth()
    );
    if (!stream) {
      stream = new Stream(this.entityId, this.entityTypeId, asOf);
      this.stream = [...(this.stream ?? []), stream];
    }
    this.markedStreams = [stream];
    this.selectedAsOf = asOf;
    this.selectedSource = undefined;
  }

  setSource(source: StreamSourceEnum) {
    this.selectedSource = source;
    let streams = Array.from(
      new Set([...(this.markedStreams ?? []), this.selectedStream])
    ).filter((point) => point);

    streams?.forEach((streamPoint) => {
      streamPoint.source = source;
      this.updateStreamPointState(streamPoint);
    });

    this.updateStreamState();
  }

  //AUM

  setCurrency(currency: string, self: StreamMeta) {
    self.selectedCurrency = currency;

    self.stream.forEach((streamPoint) => {
      if (!streamPoint.value !== null && streamPoint.value !== undefined) {
        streamPoint.currency = currency;
        self.updateStreamPointState(streamPoint);
      }
    });

    self.updateStreamState();
  }

  undoCurrency(self: StreamMeta) {
    self.selectedCurrency = undefined;
  }

  //Public Returns

  setFeeType(feeType: PublicReturnFeeEnum) {
    this.selectedFeeType = feeType;
    let streams = Array.from(
      new Set([...(this.markedStreams ?? []), this.selectedStream])
    ).filter((point) => point);

    streams?.forEach((streamPoint) => {
      streamPoint.feeType = feeType;
      this.updateStreamPointState(streamPoint);
    });

    this.updateStreamState();
  }

  setClassification(classification: PublicReturnGeneralClassificationEnum) {
    this.selectedClassification = classification;
    let streams = Array.from(
      new Set([...(this.markedStreams ?? []), this.selectedStream])
    ).filter((point) => point);

    streams?.forEach((streamPoint) => {
      streamPoint.classification = classification;
      this.updateStreamPointState(streamPoint);
    });

    this.updateStreamState();
  }

  setEstimationAsOf(asOf: Date) {
    this.selectedEstimationAsOf = asOf;
    this.selectedStream.estimationAsOf = asOf;
    this.updateStreamPointState(this.selectedStream);

    this.updateStreamState();
  }

  initStream(stream: Array<Stream>, asof: Date) {
    if (stream?.length > 0 && this.streamStartDate) {
      this.stream = stream;
      if (
        this.streamType !== PERIODIC_STREAMS.PUBLICRETURNS &&
        this.streamType !== PERIODIC_STREAMS.AUM
      ) {
        this.selectedStream = asof
          ? this.stream.find(
              (point) =>
                point.asOf.getFullYear() === asof.getFullYear() &&
                point.asOf.getMonth() === asof.getMonth()
            )
          : this.stream[this.stream.length - 1];
        this.selectedAsOf = this.selectedStream?.asOf ?? asof;
        this.selectedSource = this.selectedStream?.source;
      } else if (this.streamType === PERIODIC_STREAMS.AUM) {
        this.selectedCurrency = this.stream.find(
          (point) => point.currency
        )?.currency;
      }
    }
    if (!this.stream) {
      this.stream = [];
    }
  }

  updateStream(streamPoint: Stream) {
    let existingPoint = this.stream?.find(
      (point) =>
        point.asOf.getFullYear() === streamPoint.asOf.getFullYear() &&
        point.asOf.getMonth() === streamPoint.asOf.getMonth()
    );

    if (streamPoint && this.streamStartDate && !existingPoint) {
      if (this.stream) {
        this.stream.push(streamPoint);
      } else {
        this.stream = [streamPoint];
      }
    }

    if (
      this.streamType !== PERIODIC_STREAMS.PUBLICRETURNS &&
      this.streamType !== PERIODIC_STREAMS.AUM
    ) {
      this.selectedStream = existingPoint ?? streamPoint;
      this.selectedAsOf = this.selectedStream.asOf;
      this.selectedSource = this.selectedStream.source;
    }
  }

  updateStreamPointState(point: Stream) {
    if (point.markedForDeletion) {
      point.state = ModelState.IsDirty;
    } else if (point.value !== undefined && point.value !== null) {
      if (point.isPublic) {
        point.state =
          point.feeType && point.classification && point.source
            ? ModelState.IsDirty
            : ModelState.HasError;
      } else if (point.isAUM) {
        point.state = point.currency ? ModelState.IsDirty : ModelState.HasError;
      } else {
        point.state = point.source ? ModelState.IsDirty : ModelState.HasError;
      }
    }
  }

  updateStreamState() {
    this.state = this.stream?.some(
      (point) => point.state === ModelState.HasError
    )
      ? ModelState.HasError
      : this.stream?.some((point) => point.state === ModelState.IsDirty)
      ? ModelState.IsDirty
      : ModelState.Ready;
    this.streamSpreadSheet?.streamToSpreadsheet(this.stream);
  }

  updateSpreadsheet(includeCurrentMonth: boolean = false) {
    if (!this.streamSpreadSheet) {
      this.streamSpreadSheet = new StreamSpreadSheet(
        this.entityId,
        this.entityTypeId,
        this.streamStartDate,
        this.columnTitles,
        [new Date().getFullYear().toString()],
        Array.from(Array(12), (_, i) => new Date().getMonth() < i)
      );
      this.streamSpreadSheet.includeCurrentMonth = includeCurrentMonth;
      if (
        [
          PERIODIC_STREAMS.GROSSINCOMERETURN,
          PERIODIC_STREAMS.NETINCOMERETURN,
          PERIODIC_STREAMS.GROSSAPPRECIATIONRETURN,
          PERIODIC_STREAMS.NETAPPRECIATIONRETURN,
          PERIODIC_STREAMS.TOTALGROSSRETURN,
          PERIODIC_STREAMS.TOTALNETRETURN,
        ].includes(this.streamType)
      ) {
        this.streamSpreadSheet.disabledMonths = [0, 1, 3, 4, 6, 7, 9, 10];
      }
    } else {
      this.streamSpreadSheet.entityStartDate = this.streamStartDate;
    }

    if (this.streamStartDate) {
      this.streamSpreadSheet.streamToSpreadsheet(this.stream);
    }
  }

  getHistoricalStream(callBack?: Function, callBackArgs?: any) {
    this.state = ModelState.Loading;
    this.selectedStream = undefined;

    let providerSvc = StreamMeta.providerServices.get(this.streamType);
    providerSvc.getHistoricalStream
      .call(providerSvc, this.entityId, this.streamType, this.entityTypeId)
      .pipe(first())
      .subscribe({
        next: (data: unknown) => {
          let dataPoints = (this.streamType === PERIODIC_STREAMS.AUM
            ? (data as BulkAUM)?.aum
                .filter(
                  (point) => point.entityTypeId === EntityTypeEnum.Program
                )
                .map((point) => {
                  let { amount, asOfDate, ...rest } = point;
                  return {
                    value: amount,
                    asOf: asOfDate,
                    isAUM: true,
                    isNew: !amount,
                    ...rest,
                  };
                })
            : (data as IPeriodicStream)
                ?.datapoints) as unknown as Array<IPeriodicStreamPoint>;

          this.stream = plainToClass(Stream, dataPoints) ?? [];
          this.initStream(this.stream, this.streamStartDate);
          this.updateSpreadsheet();
          this.streamSpreadSheet.hasFullTrackRecord = true;
          this.streamSpreadSheet.isVisible = true;

          if (callBack) {
            callBack(...(callBackArgs ?? []));
          }
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.state = ModelState.Ready;
        },
      });
  }

  getYTDCalculation(changes: { new: Array<Stream>; modified: Array<Stream> }) {
    let providerSvc = StreamMeta.providerServices.get(this.streamType);
    providerSvc.getYTDCalculation
      .call(providerSvc, this.entityId, this.entityTypeId, this.stream)
      .subscribe((data) => {
        this.streamSpreadSheet.settings.lastColumnData.fill('');
        data?.forEach((point) => {
          let yearString = new Date(point?.asOf)?.getFullYear().toString();
          if (yearString) {
            let index =
              this.streamSpreadSheet.settings.rowTitles.indexOf(yearString);
            if (index !== -1) {
              this.streamSpreadSheet.settings.lastColumnData[index] = (
                point?.value * 100
              ).toFixed(2);
            }
          }
        });
      });
  }

  spreadSheetChanged(values: Array<number>): void {
    const stream = this.streamSpreadSheet.spreadSheetToStream(
      values,
      this.stream,
      this.selectedStream,
      this.streamType
    );
    stream?.new?.forEach((point) => {
      this.updateStreamPointState(point);
    });
    stream?.modified?.forEach((point) => {
      this.updateStreamPointState(point);
    });

    if (this.streamType === PERIODIC_STREAMS.PUBLICRETURNS) {
      this.getYTDCalculation({ new: stream.new, modified: stream.modified });
    }

    this.updateStreamState();

    if (!this.selectedStream && this.emptySelectedAsOf) {
      let selectedStream = this.stream.find(
        (point) =>
          point.asOf.getFullYear() === this.emptySelectedAsOf.getFullYear() &&
          point.asOf.getMonth() === this.emptySelectedAsOf.getMonth()
      );
      this.selectedStream = selectedStream;
    }
  }

  constructor(
    entityId: number,
    entityTypeId: EntityTypeEnum,
    startDate: Date,
    entityName: string,
    streamType: PeriodicStreamType,
    providerService: any
  ) {
    super();
    this.entityId = entityId;
    this.entityTypeId = entityTypeId;
    this.entityName = entityName;

    this.streamStartDate = startDate;
    this.state = ModelState.Ready;
    this.streamType = streamType;
    this.classType = `${streamType}Meta`;
    this.sources =
      this.streamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? PublicReturnSourceEnum.toKeyValue().filter(
            (source) => source.key !== PublicReturnSourceEnum.AksiaTemplate
          )
        : [
            PERIODIC_STREAMS.GROSSINCOMERETURN,
            PERIODIC_STREAMS.NETINCOMERETURN,
            PERIODIC_STREAMS.GROSSAPPRECIATIONRETURN,
            PERIODIC_STREAMS.NETAPPRECIATIONRETURN,
            PERIODIC_STREAMS.TOTALGROSSRETURN,
            PERIODIC_STREAMS.TOTALNETRETURN,
          ].includes(this.streamType)
        ? PublicReturnSourceEnum.toKeyValue()
        : this.streamType === PERIODIC_STREAMS.AUM
        ? AUMSourceEnum.toKeyValue()
        : StreamSourceEnum.toKeyValue();
    StreamMeta.providerServices.set(streamType, providerService);
    this.auditURL =
      this.streamType === PERIODIC_STREAMS.AUM
        ? `aum/auditlogs/{0}/{1}/{2}/{3}/{4}`
        : this.auditURL;
  }
}
