import {
  Column,
  ValueType,
  Workbook,
  Worksheet,
  Comment,
  stream,
} from 'exceljs';
import * as fs from 'file-saver';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  AUMSourceEnum,
  DataSourceEnum,
  EntityTypeEnum,
  PublicReturnFeeEnum,
  PublicReturnGeneralClassificationEnum,
  PublicReturnSourceEnum,
  StreamSourceEnum,
} from '../enums/enums';
import { ClosedShareClass, Fund, ManagementCompany, Program } from '../classes';
import { CompanyService } from './company.service';
import { FundService } from './fund.service';
import {
  AuthenticationService,
  Meta,
  ModelState,
  toDateFormat,
  toEndOfMonth,
  toLocalDate,
  toLocalDateString,
  toLocalISOString,
} from '@aksia-monorepo/shared-ui';
import { CompanyUtils } from '../classes/company/company.utils';
import { FundUtils } from '../classes/fund/fund.utils';
import { NoteService } from './note.service';
import { NoteDTO } from '@aksia-monorepo/o2';
import {
  PERIODIC_STREAMS,
  PeriodicStreamType,
} from './periodic-streams.service';

export interface IExcelImportable {
  importToSheet: (worksheet: Worksheet) => void;
}

const columnMap = {
  entity: 1,
  prop: 2,
  dependency: 3,
  dependencyValue: 4,
  label: 5,
  value: 6,
  dataType: 7,
  import: 8,
};

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  data$: BehaviorSubject<Worksheet> = new BehaviorSubject(null);
  onePager$: BehaviorSubject<Worksheet> = new BehaviorSubject(null);
  enums: Map<string, Map<string, string | number>>;
  targetEntity: ManagementCompany | Fund;
  noteEntityTypeId: EntityTypeEnum;
  noteEntityId: number;

  constructor(
    private auth: AuthenticationService,
    private companyService: CompanyService,
    private fundService: FundService,
    private noteService: NoteService
  ) {}

  readOnePager(event, entity: ManagementCompany | Fund, callBack?: () => void) {
    this.targetEntity = entity;
    const workbook = new Workbook();
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const arrayBuffer = new Response(target.files[0]).arrayBuffer();
    arrayBuffer.then((data) => {
      workbook.xlsx.load(data).then(() => {
        //this.onePager$.next(workbook.worksheets[0]);
        this.loadEnums(workbook.getWorksheet('Dropdowns'));
        this.applyValues(workbook.getWorksheet('Information'), entity);
        if (callBack) {
          callBack();
        }
      });
    });
  }

  readExcel(event, importable: IExcelImportable) {
    const workbook = new Workbook();
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }

    const arrayBuffer = new Response(target.files[0]).arrayBuffer();
    arrayBuffer.then((data) => {
      workbook.xlsx.load(data).then(() => {
        importable.importToSheet(workbook.getWorksheet(1));
      });
    });
  }

  writeStreamToExcel(
    headers: Array<string>,
    data,
    streamType: PeriodicStreamType
  ) {
    data.forEach((element) => {
      element[0] = new Date(toLocalISOString(element[0]));
      if (streamType === PERIODIC_STREAMS.PUBLICRETURNS && element[5]) {
        element[5] = new Date(toLocalISOString(element[5]));
      }
    });
    let sources = [
      PERIODIC_STREAMS.PUBLICRETURNS,
      PERIODIC_STREAMS.NETINCOMERETURN,
      PERIODIC_STREAMS.GROSSINCOMERETURN,
      PERIODIC_STREAMS.NETAPPRECIATIONRETURN,
      PERIODIC_STREAMS.GROSSAPPRECIATIONRETURN,
      PERIODIC_STREAMS.TOTALNETRETURN,
      PERIODIC_STREAMS.TOTALGROSSRETURN,
    ].includes(streamType)
      ? streamType === PERIODIC_STREAMS.PUBLICRETURNS
        ? PublicReturnSourceEnum.toKeyValue()
            .filter(
              (source) => source.key !== PublicReturnSourceEnum.AksiaTemplate
            )
            .map((source) => source.value)
        : PublicReturnSourceEnum.toKeyValue().map((source) => source.value)
      : streamType === PERIODIC_STREAMS.AUM
      ? AUMSourceEnum.toKeyValue().map((source) => source.value)
      : StreamSourceEnum.toKeyValue().map((source) => source.value);
    let fees = PublicReturnFeeEnum.toKeyValue().map((fee) => fee.value);
    let entityTypes = PublicReturnGeneralClassificationEnum.toKeyValue().map(
      (entity) => entity.value
    );

    const workbook = new Workbook();
    let worksheet = workbook.addWorksheet(headers[1]);
    let columns = headers.map((h, i) => ({
      header: h,
      key: h,
      width: i === 0 ? 15 : i === 1 ? 20 : 30,
    }));
    worksheet.columns = columns as Column[];
    //worksheet.columns[0].numFmt = 'mm/dd/yy';
    if (streamType === PERIODIC_STREAMS.PUBLICRETURNS) {
      worksheet.columns[1].numFmt = '0.00%';
    }
    //worksheet.columns[5].numFmt = 'mm/dd/yy';
    worksheet.getRow(1).font = { bold: true };

    data.forEach((row, i) => {
      if (streamType === PERIODIC_STREAMS.PUBLICRETURNS && row[1]) {
        row[1] = row[1] / 100;
      }
      let addedRow = worksheet.addRow(row);

      let cellA = addedRow.getCell('A');
      cellA.dataValidation = {
        type: 'date',
        operator: 'lessThan',
        allowBlank: true,
        showErrorMessage: true,
        formulae: [toEndOfMonth(new Date())],
      };
      let cellB = addedRow.getCell('B');
      /* cellB.dataValidation = {
        type: 'decimal',
        operator: 'greaterThan',
        allowBlank: true,
        showErrorMessage: true,
        formulae: [0],
      }; */
      let cellC = addedRow.getCell('C');
      cellC.alignment = { horizontal: 'right' };
      cellC.dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Sources!$A$2:$A$100'],
      };

      if (headers.length > 3) {
        let cellD = addedRow.getCell('D');
        cellD.alignment = { horizontal: 'right' };
        cellD.dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['Fees!$A$2:$A$100'],
        };
        let cellE = addedRow.getCell('E');
        cellE.alignment = { horizontal: 'right' };
        cellE.dataValidation = {
          type: 'list',
          allowBlank: true,
          formulae: ['EntityTypes!$A$2:$A$100'],
        };
        let cellF = addedRow.getCell('F');
        cellF.alignment = { horizontal: 'right' };
        cellF.dataValidation = {
          type: 'date',
          operator: 'lessThan',
          allowBlank: true,
          showErrorMessage: true,
          formulae: [toEndOfMonth(new Date())],
        };
      }
    });

    let sourcesWorksheet = workbook.addWorksheet('Sources');
    let sourceColumns = [{ header: 'Id', width: 30 }];
    sourcesWorksheet.columns = sourceColumns as Column[];
    worksheet.getRow(1).font = { bold: true };

    sources.forEach((source) => {
      let addedRow = sourcesWorksheet.addRow([source]);
    });

    if (headers.length > 3) {
      let feesWorksheet = workbook.addWorksheet('Fees');
      let feeColumns = [{ header: 'Id', width: 30 }];
      feesWorksheet.columns = feeColumns as Column[];
      worksheet.getRow(1).font = { bold: true };

      fees.forEach((fee) => {
        let addedRow = feesWorksheet.addRow([fee]);
      });

      let entityTypesWorksheet = workbook.addWorksheet('EntityTypes');
      let entityTypeColumns = [{ header: 'Id', width: 30 }];
      entityTypesWorksheet.columns = entityTypeColumns as Column[];
      worksheet.getRow(1).font = { bold: true };

      entityTypes.forEach((entityType) => {
        let addedRow = entityTypesWorksheet.addRow([entityType]);
      });
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, `${headers[1]}_Export.xlsx`);
    });
  }

  writeExcel(headers: Array<string>, data, sheetName: string = null) {
    let sources = AUMSourceEnum.toKeyValue().map((source) => source.value); //.join(", ");
    const workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName ?? 'AUM');
    let columns = headers.map((h, i) => ({
      header: h,
      key: h,
      width: i === 0 ? 15 : i === 1 ? 20 : 30,
    }));
    worksheet.columns = columns as Column[];
    worksheet.columns[0].numFmt = 'mm/dd/yyyy';
    worksheet.getRow(1).font = { bold: true };

    data.forEach((row, i) => {
      row[0] = toDateFormat(toEndOfMonth(row[0] as Date));
      let addedRow = worksheet.addRow(row);
      let cellA = addedRow.getCell('A');
      cellA.dataValidation = {
        type: 'date',
        operator: 'lessThan',
        allowBlank: true,
        showErrorMessage: true,
        formulae: [toEndOfMonth(new Date())],
      };
      let cellB = addedRow.getCell('B');
      cellB.dataValidation = {
        type: 'decimal',
        operator: 'greaterThan',
        allowBlank: true,
        showErrorMessage: true,
        formulae: [0],
      };
      let cellC = addedRow.getCell('C');
      cellC.alignment = { horizontal: 'right' };
      cellC.dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Sources!$A$2:$A$10000'],
      };
    });

    let sourcesWorksheet = workbook.addWorksheet('Sources');
    let sourceColumns = [{ header: 'Id', width: 30 }];
    sourcesWorksheet.columns = sourceColumns as Column[];
    worksheet.getRow(1).font = { bold: true };

    sources.forEach((source) => {
      let addedRow = sourcesWorksheet.addRow([source]);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, 'AUM_Export.xlsx');
    });
  }

  toKeyValue(
    worksheet: Worksheet,
    sourceKeyValue: Array<{ value: string; key: any }>
  ): Array<{
    key: Date;
    value: number;
    source: number;
    fees?: number;
    entityTypes?: number;
  }> {
    let keyValues: Array<{
      key: Date;
      value: number;
      source: number;
      fees?: number;
      entityTypes?: number;
    }> = new Array();
    let minYear = Number.POSITIVE_INFINITY;
    let maxYear = Number.NEGATIVE_INFINITY;
    worksheet.eachRow((row) => {
      if (!isNaN(Date.parse(row.getCell(1).value?.toString()))) {
        let inputDate = new Date(row.getCell(1).value?.toString());
        let date = toEndOfMonth(inputDate);
        let amount =
          row.getCell(2).type == ValueType.Number
            ? (row.getCell(2).value as number)
            : null;
        let source =
          sourceKeyValue.find((e) => e.value === row.getCell(3).value)?.key ??
          null;
        let fees =
          PublicReturnFeeEnum.toKeyValue().find(
            (e) => e.value === row.getCell(4).value
          )?.key ?? null;
        let entityTypes =
          PublicReturnGeneralClassificationEnum.toKeyValue().find(
            (e) => e.value === row.getCell(5).value
          )?.key ?? null;

        keyValues.push({
          key: date,
          value: amount,
          source: source,
          fees: fees,
          entityTypes: entityTypes,
        });
        minYear = date.getFullYear() < minYear ? date.getFullYear() : minYear;
        maxYear = date.getFullYear() > maxYear ? date.getFullYear() : maxYear;
      }
    });
    if (keyValues.length > 0) {
      keyValues = keyValues.sort((a, b) => {
        if (
          a.key.getFullYear() > b.key.getFullYear() ||
          (a.key.getFullYear() == b.key.getFullYear() &&
            a.key.getMonth() > b.key.getMonth())
        )
          return 1;
        else return -1;
      });
      let minMonth = keyValues[keyValues.length - 1].key.getMonth();
      let maxMonth = keyValues[0].key.getMonth();
      keyValues = [
        ...Array.from({ length: 11 - minMonth }, (_, i) => {
          return {
            key: new Date(maxYear, minMonth + i + 1, 15),
            value: null,
            source: null,
          };
        }),
        ...keyValues,
        ...Array.from({ length: maxMonth }, (_, i) => {
          return {
            key: new Date(minYear, maxMonth - i - 1, 15),
            value: null,
            source: null,
          };
        }),
      ];
      keyValues = keyValues.sort((a, b) => {
        if (
          a.key.getFullYear() > b.key.getFullYear() ||
          (a.key.getFullYear() == b.key.getFullYear() &&
            a.key.getMonth() < b.key.getMonth())
        )
          return -1;
        else return 1;
      });
    }
    return keyValues;
  }

  private loadEnums(worksheet: Worksheet) {
    this.enums = new Map<string, Map<string, number | string>>();
    let currentEnum;
    worksheet.eachRow((row, rowNumber) => {
      const value = row?.getCell(1).value as number | string;
      const key = row?.getCell(2).value as string;
      if (row.getCell(1).isMerged) {
        const enumLabel = row.getCell(1).value as string;
        this.enums.set(enumLabel, new Map<string, number | string>());
        currentEnum = this.enums.get(enumLabel);
      } else if (row.getCell(1).value) {
        currentEnum.set(key, value);
      }
    });
  }

  private applyValues(worksheet: Worksheet, entity: ManagementCompany | Fund) {
    let shareClass;

    if (entity instanceof ManagementCompany) {
      this.targetEntity = CompanyUtils.copy(entity);
    } else {
      this.targetEntity = FundUtils.copy(entity);
      shareClass = this.targetEntity.shareClasses.find(
        (sh) => sh.className === this.fundService.selectedClass.className
      );
    }

    this.targetEntity.dataSource = DataSourceEnum.AksiaTemplate;

    worksheet.eachRow((row, rowNumber) => {
      const targetEntity = row?.getCell(columnMap.entity)?.value as string;
      const ruleCell = row?.getCell(columnMap.import);
      let importRow = ruleCell.formula
        ? (ruleCell.value as object)['result'] !== 'Hide'
        : ruleCell.value !== 'Hide';
      const valueCell = row?.getCell(columnMap.value);
      const value = valueCell?.formula
        ? (valueCell?.value as object)['result']
        : valueCell?.isHyperlink
        ? (valueCell?.value as object)['text']
        : valueCell?.value;
      const note = row?.getCell(columnMap.value)?.note;
      const noteText = note
        ? typeof note === 'string'
          ? note
          : (note as Comment).texts[0]?.text
        : null;

      if (
        (targetEntity === '*' ||
          (this.targetEntity instanceof ManagementCompany &&
            targetEntity === 'ManagementCompany') ||
          (this.targetEntity instanceof Fund &&
            ['Fund', 'ShareClass'].includes(targetEntity))) &&
        importRow
      ) {
        this.noteEntityId =
          targetEntity === 'ShareClass'
            ? shareClass.classId
            : this.targetEntity instanceof Fund
            ? this.targetEntity.fundId
            : this.targetEntity.managementCompanyId;
        this.noteEntityTypeId =
          targetEntity === 'ShareClass'
            ? EntityTypeEnum.Shareclass
            : this.targetEntity instanceof Fund
            ? EntityTypeEnum.Fund
            : EntityTypeEnum.ManagementCompany;

        const prop = row?.getCell(columnMap.prop)?.value as string;
        const dataType = row?.getCell(columnMap.dataType)?.value as string;
        const props = prop?.split('.');

        const dependency = row?.getCell(columnMap.dependency).value as string;
        const dependencyProps = dependency?.split('.');

        if (dependency) {
          const dependencyValueCell = row?.getCell(columnMap.dependencyValue);
          const dependencyValueRaw = dependencyValueCell?.formula
            ? ((dependencyValueCell?.value as object)['result'] as string)
            : (dependencyValueCell?.value as string);
          const dependencyValue = dependencyValueRaw?.toString().includes(',')
            ? dependencyValueRaw
                .split(',')
                .map((x) =>
                  x === 'null'
                    ? null
                    : dependencyValueCell.type === ValueType.String
                    ? +x
                    : x
                )
            : dependencyValueRaw === 'null'
            ? null
            : dependencyValueRaw;

          if (targetEntity === 'ShareClass') {
            this.setPropValue(
              dependencyProps,
              shareClass,
              dependencyValue,
              'Number'
            );
          } else {
            this.setPropValue(
              dependencyProps,
              this.targetEntity,
              dependencyValue,
              'Number'
            );
          }
        }

        if (targetEntity === 'ShareClass') {
          this.setPropValue(props, shareClass, value, dataType, noteText);
        } else {
          this.setPropValue(
            props,
            this.targetEntity,
            value,
            dataType,
            noteText
          );
        }
      }
    });

    if (this.targetEntity instanceof ManagementCompany) {
      this.companyService.company = this.targetEntity;
      this.companyService.company.state = ModelState.IsDirty;
      this.companyService.company.aumMeta.state = ModelState.IsDirty;
    } else {
      this.fundService.selectedClass = shareClass;
      this.fundService.fund = this.targetEntity;
      this.fundService.fund.state = ModelState.IsDirty;
      this.fundService.selectedClass.state = ModelState.IsDirty;
    }
  }

  setPropValue(
    props: Array<string>,
    model: Meta,
    value: unknown,
    dataType: string,
    noteText?: string
  ) {
    let prop = props.shift();

    if (prop) {
      if (prop.includes('?')) {
        let index = 0;
        let param = prop.split('?')[1];
        prop = prop.split('?')[0];

        if (isNaN(+param)) {
          if ((param as string).includes('=')) {
            let paramValue = param.split('=')[1];
            param = param.split('=')[0];
            index = (model[prop] as Array<Meta>).findIndex(
              (item) =>
                item[param] === +paramValue &&
                !item.isDeleted &&
                !item.markedForDeletion
            );
          } else {
            index = (model[prop] as Array<Meta>).findIndex(
              (item) =>
                item[param] && !item.isDeleted && !item.markedForDeletion
            );
          }
          if (index === -1) {
            model.add(prop, true);
            index = (model[prop] as Array<Meta>)?.length - 1;
          }
        } else {
          const realIndex = ((model[prop] as Array<Meta>) ?? []).findIndex(
            (item) => !item.isDeleted && !item.markedForDeletion
          );
          index = Math.max(+param + realIndex, 0);
          if (((model[prop] as Array<Meta>) ?? [])?.length <= index) {
            model.add(prop, true);
          }
        }

        if (props.length !== 0) {
          this.setPropValue(
            props,
            model[prop][index],
            value,
            dataType,
            noteText
          );
        } else {
          if (Array.isArray(value)) {
            model[prop][index] = value[0];
            model[prop][index] = value[1];
          } else if (value !== '' && value !== null && value !== undefined) {
            model[prop][index] = this.resolveValue(value, dataType);
          }
          if (noteText !== '' && noteText !== null && noteText !== undefined) {
            this.resolveNote(model, prop, noteText);
          }
        }
      } else {
        if (props.length !== 0) {
          this.setPropValue(props, model[prop], value, dataType, noteText);
        } else {
          if (Array.isArray(value)) {
            model[prop] = value[0];
            model[prop] = value[1];
          } else if (value !== '' && value !== null && value !== undefined) {
            model[prop] = this.resolveValue(value, dataType);
          }
          if (noteText !== '' && noteText !== null && noteText !== undefined) {
            this.resolveNote(model, prop, noteText);
          }
        }
      }
    }
  }

  private resolveValue(value: unknown, dataType: string) {
    switch (dataType) {
      case 'Rate': {
        return (value as number) * 100;
      }
      case 'YearMonth': {
        const yearMonth = toEndOfMonth(value as Date);
        return yearMonth;
      }
      case 'Date': {
        if (value instanceof Date) {
          const utcDate = new Date(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate()
          );
          return utcDate;
        }
      }
      case 'Number':
      case 'Currency':
      case 'Text':
        return value;
      default: {
        const list = this.enums.get(dataType.split('_')[1]);
        if (list) {
          return list.get(value as string);
        }
        return null;
      }
    }
  }

  private resolveNote(model: Meta, prop: string, noteText: string) {
    const propWithPath = model.getAncestorPath(prop);

    if (this.noteEntityId === -1) {
      const alias = model.getClassMeta('alias', prop) ?? prop;
      this.fundService.declinedNotes.push({
        key: `(Note) ${alias}`,
        value: noteText,
        desc: ' - Could not be imported due to unsaved Shareclass',
      });
      return;
    }

    let note = this.noteService._o2Notes.get(
      `${propWithPath}_${this.noteEntityTypeId}_${this.noteEntityId}`
    );
    if (note) {
      note.note = noteText;
      note._isDirty = true;
      this.noteService._o2Notes.set(
        `${propWithPath}_${this.noteEntityTypeId}_${this.noteEntityId}`,
        note
      );
    } else {
      const newNote: NoteDTO = {
        entityId: this.noteEntityId,
        entityTypeId: this.noteEntityTypeId,
        fieldName: this.noteService.formatFieldName(propWithPath, true),
        modifiedBy: this.auth.user.username,
        modifiedOn: new Date().toISOString(),
        note: noteText,
        system: 'O2',
      };
      const noteKV = this.noteService.toMapModel(
        newNote,
        this.targetEntity instanceof ClosedShareClass
          ? (this.targetEntity.parent as Fund)
          : this.targetEntity
      );
      noteKV.noteValue._isDirty = true;
      this.noteService._o2Notes.set(
        `${propWithPath}_${this.noteEntityTypeId}_${this.noteEntityId}`,
        noteKV.noteValue
      );
    }
    this.noteService.state = ModelState.IsDirty;
  }
}
